import './shopping_list.css'
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie"

import RecipesList from "./recipes_list/recipes_list.js"
import IngredientsList from "./ingredients_list/ingredients_list.js"

import { translate } from '../translations/translations.js'
import ButtonPrimary from '../components/button/button_primary.js'

function ShoppingList() {

    const session_id = Cookies.get("session_id")
    const language = Cookies.get("language") || "english";
    
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [recipesList, setRecipesList] = useState({}); 
    const [shoppingList, setShoppingList] = useState([]);
    // const [ingredientToRecipes, setIngredientToRecipes] = useState({});

    const navigate = useNavigate();

    const handleDataMemoize = useCallback(handleData, [])
    const fetchUserShoppingListMemoize = useCallback(fetchUserShoppingList, [session_id, language])

    useEffect(()=> {
        document.title = "Garlic Basil - Basket"
    },[])

    useEffect(() => {
        fetchUserShoppingListMemoize()
    }, [fetchUserShoppingListMemoize]);

    function fetchUserShoppingList() {

        if (typeof session_id !== "undefined") {
            const options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({session_id: session_id, language: language}),
            };
            fetch(process.env.REACT_APP_API_URL + "/api/getUserShoppingList", options)
            .then((response) => response.json())
            .then((json) => {setRawData(json); setDataLoaded(true);});
        } else {
            setDataLoaded(true)
        }
    }

    useEffect(() => { 
        handleDataMemoize(rawData)
    }, [rawData, handleDataMemoize]);

    function handleData(data) {
        handleRecipesListData(data)
        handleShoppingListData(data)
    }
        
    function handleRecipesListData(data) {

        const tmpData = data[0]
        let recipesListDict= {}

        if (tmpData !== undefined) {
            for(var i=0; i < tmpData.length; i++) {

                const recipe_id = tmpData[i].recipe_id

                if (recipe_id in recipesListDict) {
                    recipesListDict[recipe_id].ingredient_count =  recipesListDict[recipe_id].ingredient_count + 1
                    if (tmpData[i].completed) {
                        recipesListDict[recipe_id].completed =  recipesListDict[recipe_id].completed + 1
                    } 
                } else {
                    if (tmpData[i].completed) {
                        recipesListDict[recipe_id] = {recipe_id: tmpData[i].recipe_id, recipe_name: tmpData[i].recipe_name, servings: tmpData[i].servings, ingredient_count: 1, completed: 1 }
                    } else {
                        recipesListDict[recipe_id] = {recipe_id: tmpData[i].recipe_id, recipe_name: tmpData[i].recipe_name, servings: tmpData[i].servings, ingredient_count: 1, completed: 0 }
                    }
                }
            }
        }

        setRecipesList(recipesListDict)
    }
    
    function handleShoppingListData(data) {
        console.log(data)
        const tmpData = data[0]
        let shoppingListTmp = []
        if (tmpData !== undefined) {
            for(var i=0; i < tmpData.length; i++) {
                shoppingListTmp.push({servings: tmpData[i].servings, ingredient: tmpData[i].ingredient, ingredient_id: tmpData[i].ingredient_id, plural_description: tmpData[i].plural_description, quantity: tmpData[i].quantity, uom: tmpData[i].uom, completed: tmpData[i].completed, hash_id: tmpData[i].hash_id, recipe_servings: tmpData[i].recipe_servings})
            }
        }  
        setShoppingList(shoppingListTmp)
    }

    function updateRecipesList(ingredient, uom, status) {

        const tmpRawData = {...rawData}
        const tmpData = tmpRawData[0]

        for (var i=0; i < tmpData.length; i++) {
            if (tmpData[i].ingredient === ingredient && tmpData[i].uom === uom) {
                if (status === "completed") {
                    tmpData[i].completed = 0
                } else {
                    tmpData[i].completed = 1
                }
            }
        }

        setRawData([tmpData])

    } 

    function updateIngredientsQuantity(recipe_id, servings) {
  
        const tmpRawData = {...rawData}
        const tmpData = tmpRawData[0]
        
        for (var i=0; i < tmpData.length; i++) {
            if (tmpData[i].recipe_id === recipe_id) {
                tmpData[i].servings = servings
            }
        }

        setRawData([tmpData])
    }

    function showEmptyCart() {
        return (
            <div className="empty_shopping_list_container">
                <div className="empty_shopping_list">
                    <picture>
                        <source srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/empty_basket.webp" />
                        <img className="empty_shopping_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/empty_basket.png" alt="empty_shopping_basket"/>
                    </picture>
                    <p>{translate("basket", "basket no items info")}</p>
                    <ButtonPrimary name={translate("basket", "explore recipes")} action={goToRecipes} icon="travel_explore"/>
                    {/* <button className="explore_recipes primary_colour" name="See Recipes" onClick={goToRecipes}>{translate("basket", "explore recipes")}</button>  */}
                </div>
            </div>
        )
    }

    function showBasketItems() {
        return (
            <div className="shopping_list_content_child_container">
                {Object.keys(recipesList).length === 0 ? null : <RecipesList recipesList={recipesList} updateShoppingList={fetchUserShoppingList} updateIngredientsQuantity={updateIngredientsQuantity}/> }
                {Object.keys(recipesList).length === 0 ? null : <IngredientsList shoppingList={shoppingList} updateRecipesList={updateRecipesList}/> }
            </div>        
        )
    }

    function goToRecipes() {
        navigate("/recipes-search");
    }

    return (
        <div className="shopping_list_container">
            <div className="shopping_list_background"></div>
            <div className="shopping_list_content_container">
                {dataLoaded ? Object.keys(recipesList).length === 0 ? showEmptyCart() : showBasketItems() : null}
            </div>
        </div>
    );

}

export default ShoppingList;