import "./ingredients_item.css"
import React from "react"
import Cookies from "js-cookie";
import IngredientTile from "../../../components/ingredient_tile/ingredient_tile.js"

function IngredientsItem(props) {

    const isMobile = window.innerWidth < 768
    const status = props.status
    const session_id = Cookies.get("session_id")
    
    function toggleComplete() {
        updateDatabase()
        props.toggleComplete(props.keyID, props.name, props.uom, status)
    }

    function updateDatabase() {

        var options = {}

        if (status !== "not_completed") { 
            options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({session_id: session_id, completed: false, ingredient: props.ingredient_id}),
            };
        } else {
            options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({session_id: session_id, completed: true, ingredient: props.ingredient_id}),
            };
        }

        fetch(process.env.REACT_APP_API_URL + "/api/updateUserShoppingList", options)
    }
    
    function handleMouseEnter() {
        document.getElementById(props.name + props.uom + "_status_container").style.opacity = 1
    }

    function handleMouseLeave() {
        document.getElementById(props.name + props.uom + "_status_container").style.opacity = 0
    }

    return (
        <div className="ingredients_item_parent_container" onClick={toggleComplete} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div id={props.name + props.uom + "_status_container"} className="ingredients_item_status_container" style={props.status === "not_completed" ? {backgroundColor:"var(--primary-color)"} : {backgroundColor:"red"}}>
                <span className="material-symbols-outlined ingredients_item_status_icon">{props.status === "not_completed" ? "task_alt" : "cancel" }</span>
            </div>
            {isMobile ?
                <IngredientTile width={"calc((100vw - 2em - 15px) / 3)"} height={"calc(((100vw - 2em - 15px) / 3) - 20px)"} name={props.name} name_plural={props.name_plural} hash_id={props.hash_id} quantity={props.quantity} uom={props.uom}/>
                :
                <IngredientTile width={144} height={122} name={props.name} name_plural={props.name_plural} hash_id={props.hash_id} quantity={props.quantity} uom={props.uom}/>
            }
        </div>
    )

}

export default IngredientsItem;